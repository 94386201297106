import { type TFuncKey } from 'i18next';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

type Props = {
  titleKey: TFuncKey | TFuncKey[];
  // eslint-disable-next-line react/boolean-prop-naming
  trackPageView?: boolean;
};

const PageMeta = ({ titleKey, trackPageView = true }: Props) => {
  const { t } = useTranslation();
  const keys = typeof titleKey === 'string' ? [titleKey] : titleKey;
  const title = keys.map((key) => t(key)).join(' - ');
  return <Helmet title={title} />;
};

export default PageMeta;
