export var ConnectorErrorCodes;
(function (ConnectorErrorCodes) {
    ConnectorErrorCodes["General"] = "general";
    ConnectorErrorCodes["InvalidMetadata"] = "invalid_metadata";
    ConnectorErrorCodes["UnexpectedType"] = "unexpected_type";
    ConnectorErrorCodes["InvalidConfigGuard"] = "invalid_config_guard";
    ConnectorErrorCodes["InvalidRequestParameters"] = "invalid_request_parameters";
    ConnectorErrorCodes["InsufficientRequestParameters"] = "insufficient_request_parameters";
    ConnectorErrorCodes["InvalidConfig"] = "invalid_config";
    ConnectorErrorCodes["InvalidCertificate"] = "invalid_certificate";
    ConnectorErrorCodes["InvalidResponse"] = "invalid_response";
    /** The template is not found for the given type. */
    ConnectorErrorCodes["TemplateNotFound"] = "template_not_found";
    /** The template type is not supported by the connector. */
    ConnectorErrorCodes["TemplateNotSupported"] = "template_not_supported";
    ConnectorErrorCodes["RateLimitExceeded"] = "rate_limit_exceeded";
    ConnectorErrorCodes["NotImplemented"] = "not_implemented";
    ConnectorErrorCodes["SocialAuthCodeInvalid"] = "social_auth_code_invalid";
    ConnectorErrorCodes["SocialAccessTokenInvalid"] = "social_invalid_access_token";
    ConnectorErrorCodes["SocialIdTokenInvalid"] = "social_invalid_id_token";
    ConnectorErrorCodes["AuthorizationFailed"] = "authorization_failed";
})(ConnectorErrorCodes || (ConnectorErrorCodes = {}));
export class ConnectorError extends Error {
    constructor(code, data) {
        const message = `ConnectorError: ${data ? JSON.stringify(data) : code}`;
        super(message);
        this.code = code;
        this.data = typeof data === 'string' ? { message: data } : data;
    }
}
